import "tailwindcss/tailwind.css"
import "./assets/scss/style.scss"

import regeneratorRuntime from "regenerator-runtime";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VTooltip from 'v-tooltip'
import Multiselect from 'vue-multiselect'
import VueHtml2Canvas from 'vue-html2canvas'


Vue.config.productionTip = false

Vue.use(VTooltip)
Vue.use(VueHtml2Canvas);
Vue.component('multiselect', Multiselect)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
