<template>
	<div class="flex flex-col bg-gray-50 w-full">
		<div class="w-full px-10 py-8">
			<div class="text-4xl font-medium font-serif mb-4">
				Accueil
			</div>
			<div class="grid grid-cols-1 gap-3">
				<router-link :to="{name:'programme'}">
					<div class="tools__row">
						Outil de génération du panneau d'accueil
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'App',
	components: {
	},
	data() {
		return {

		}
	}
}
</script>